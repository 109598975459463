// Toyota colors

// primary

$light-primary: #002849;
$light-primary-contrast: #ffffff;

$dark-primary: #002849;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #00b6b0;
$light-accent-contrast: #ffffff;

$dark-accent: #00b6b0;
$dark-accent-contrast: #ffffff;

// common

$page-background: #0028491f;
